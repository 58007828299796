/*================================================
Default CSS
=================================================*/
$font-family1: 'Open Sans', sans-serif;
$font-family2: 'Cabin', sans-serif;
$main-color: #088dd3;
$main-color2: #20aef9;
;
$optional-color: #4a6f8a;
$white-color: #ffffff;
$black-color: #000000;
$transition: .5s;
$font-size: 15px;


//-------------------------------------CODICE CSS ALE-------------------------------------//
body,
html {
    overflow-x: hidden !important;
}

body {
    //font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.sub-header-title {
    padding: 15px 40px;
    width: 100%;
    text-align: center;
    background-color: #ededed;
    text-transform: uppercase;
    font-size: 1.8em;
}



//GLOBAL SHOW&HIDE MENU HEADER/FOOTER ---------------------------------  ALE
@media only screen and (max-width: 600px) {

    .hideMenuHeaderDesktop {
        display: none !important;
    }

}

@media only screen and (min-width: 601px) {
    .hideFooterMobile {
        display: none !important;
    }
}


//ERROR 404
.error-404-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;
    width: 100%;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    .notfound {
        max-width: 520px;
    }

    .notfound-bg {
        position: relative;
        width: 100%;
    }

    h1 {
        color: red;
        font-size: 100px;
        font-weight: 700;
        margin: 0 0 15px;
    }

    h3 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 12px;
    }

}





//PROGETTAZIONE SEZIONI TUTORIALS --------------------------------------------- ALE
.tutorialIcon {
    width: 25em;
    max-width: 150px;
}

.tutorialSfondo {
    height: 100vh;
    overflow-y: hidden !important;

    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        image: url(assets/img/sfondo.png);
    }
}

.header-tutorial {
    text-align: center;
    color: #fff !important;
    background-image: linear-gradient(180deg, #0ba360 0%, #3cba92 100%);
    padding: 50px 20px 50px 20px;
}

.header-tutorial h2 {
    color: #fff !important;
}

.header-tutorial img {
    width: 25em;
    max-width: 250px;
}

.containerStep1Tutorial {
    margin: auto;
    animation: fadeIn 1s ease-in-out;
    flex: 1;
    //display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



//PROGETTAZIONE CONTAINER GLOBAL --------------------------------------------- ALE

.container-animated {
    max-width: 1200px;
    margin: auto;
    animation: fadeIn 1s ease-in-out;
    flex: 1;
    //display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


//SPINNER IN PAGE
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #efbc74;
    animation: spin 1s ease infinite;
}

//SPINNER FULL
.spinnerFull-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.8);
}

.spinnerFull {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #efbc74;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


//PROGETTAZIONE FORM GLOBAL --------------------------------------------- ALE

.form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 2em !important;
    margin: auto;
}

.form input[type=text],
input[type=number],
input[type=email],
.form input[type=password] {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form button:hover {
    background-color: #088dd3;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.form input[type=email],
.form input[type=password] {
    animation: slideUp 0.5s ease-out;
}

.form input[type=email] {
    animation-delay: 0.3s;
}

.form input[type=password] {
    animation-delay: 0.6s;
}








//PROGETTAZIONE BUTTON & LINKS --------------------------------------------- ALE

button {
    padding: 10px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.buttonLight {
    background-color: #fff;
    color: #555;
}

button:active {
    transform: scale(0.95);
}

.action-link,
.alternate-link {
    margin-top: 10px;
    color: #555;
    text-decoration: none;
}

.forgot-password,
.register-link {
    margin-top: 20px;
    color: #555;
    text-decoration: none;
    text-align: center;
}

.action-link:hover,
.alternate-link:hover,
.forgot-password:hover,
.register-link:hover {
    text-decoration: underline;
    background-color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}





//PROGETTAZIONE FOOTER --------------------------------------------- ALE

.footer {
    background-image: linear-gradient(180deg, #b5b5b5, #595959);
    padding: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10000000;
}

.tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
}

.tab-button:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.tab-button i {
    font-size: 33px;
    margin-bottom: 5px;
    color: #fff;
}

.tab-button img {
    width: 33px;
    margin-bottom: 5px;
}

.tab-button span {
    font-size: 14px;
    color: #fff;
}

.tab-button:hover {
    animation: pulse 1s infinite;
}



//PROGETTAZIONE MARGIN GLOBAL --------------------------------------------- ALE

.margin-auto {
    margin: auto;
}

.margin-content {
    margin-bottom: 100px;
    margin-top: 65px;
}

.margin-bottom50 {
    margin-bottom: 50px;
}

.margin-top50 {
    margin-top: 50px;
}



//PROGETTAZIONE ANIMAZIONI --------------------------------------------- ALE
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

//PROGETTAZIONE CARD --------------------------------------------- ALE

.cardMinHeight300 {
    min-height: 300px;
}

.card {
    width: 100%;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    animation: fadeIn 0.5s ease-out forwards;
    transform-origin: bottom center;

    .opening-hours {
        list-style: none;
        padding: 0;
        margin: 0;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        font-family: 'Arial', sans-serif;
        color: #333333;
    }

    .opening-hours li {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #f0f0f0;
    }

    .opening-hours li:last-child {
        border-bottom: none;
    }

    .day {
        font-weight: bold;
    }

    .hours {
        text-align: right;
        font-weight: normal;
    }

}

.card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #fff;
}

.card-content-transparent {
    margin-top: -90px;
    color: #fff;
    font-size: 2em;
    text-shadow: 0 0 3px black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.card-content-transparent:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.card-image {
    width: 100%;
    overflow: hidden;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.card-image:hover img {
    transform: scale(1.1);
}

@media screen and (max-width: 767px) {
    .card-image {
        height: auto;
    }

    .card-image img {
        object-fit: contain;
    }
}

.card-badge {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: #5ECFB1;
    color: #ffffff;
    text-align: center;
    z-index: 2;
    padding: 3px 12px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13.5px;
}


.card-badge-category {
    right: 20px;
    bottom: 20px;
    background-color: #5ECFB1;
    color: #ffffff;
    text-align: center;
    z-index: 2;
    padding: 3px 12px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13.5px;
}

.pointer {
    cursor: pointer;
}

.container {
    max-width: 1140px;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    color: $optional-color;
    line-height: 1.8;
    margin-bottom: 15px;
    font-size: $font-size;

    &:last-child {
        margin-bottom: 0;
    }
}

:focus {
    outline: 0 !important;
}

a {
    color: $black-color;
    text-decoration: none;
    transition: $transition;

    &:hover {
        color: $main-color;
        text-decoration: none;
    }
}

//ERRORS
.invalidInput {
    outline: 2px solid red;
}

.form-error-msg {
    margin-top: -9px;
    margin-bottom: 10px;
    font-size: 14px;
    color: red;
}

.form-success-msg {
    margin-top: -9px;
    margin-bottom: 10px;
    font-size: 14px;
    color: rgb(63, 178, 20);
}
